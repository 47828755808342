import { FC, useContext } from 'react'
import { css } from '@emotion/react'
import Img from './global/Img'
import { GlobalContext } from 'context/GlobalContext'
import Link from 'next/link'

interface Props {
  road: any
}

const YolItem: FC<Props> = ({ road }) => {
  const { baseImageURL } = useContext(GlobalContext)

  return (
    <div css={styles.container} className="max w360">
      <Link href={road?.url}>
        <Img
          path={baseImageURL}
          src={road?.media?.[0].filename}
          alt={road?.title}
          width={360}
          height={200}
          crop="fill"
        />
        <span className="title">{road?.title}</span>
        <span className="short">{road?.short}</span>
      </Link>
    </div>
  )
}
export default YolItem

const styles = {
  container: css`
    margin-bottom: 20px;
    img {
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 15px;
    }
    .title {
      width: 100%;
      display: block;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 7px;
    }
    .short {
      font-size: 14px;
    }
  `
}
