/* eslint-disable @next/next/no-img-element */
import { FC, useContext } from 'react'
import { css } from '@emotion/react'
import { GlobalContext } from 'context/GlobalContext'

const FooterLegal: FC = () => {
  const { _local } = useContext(GlobalContext)

  return (
    <div css={styles.container} className="max w940 t-center">
      <small className="legal m-top-20">
        {'© ' + new Date().getFullYear() + ' ' + _local('Her Hakkı Saklıdır.')}
      </small>
    </div>
  )
}

export default FooterLegal

const styles = {
  container: css`
    display: block;
    padding-top: 40px;
    padding-bottom: 60px;
    text-align: center;
  `
}
