/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
import { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { axiosGet } from 'helpers/apiHelpers'

const MenuRoad: FC = () => {
  const [isMenu, setMenu] = useState(false)
  const [roads, setRoads] = useState(Object)

  useEffect(() => {
    axiosGet('pages', {
      select: 'title,url',
      parent_id: '643679de6200c'
    }).then((res: any) => {
      if (!res.error) setRoads(res)
    })
  }, [])

  return (
    <div css={styles.container}>
      <div className="pointer" onClick={() => setMenu(!isMenu)}>
        {!isMenu ? (
          <img src="/media/icon-menu.svg" width={22} height={22} alt="menu" />
        ) : (
          <img src="/media/icon-close.svg" width={22} height={22} alt="close" />
        )}
      </div>
      <div className={isMenu ? 'menu_url show' : 'menu_url'}>
        {roads.length > 0 &&
          roads?.map((road: any, index: number) => (
            <Link className="max btn-s primary" key={index} href={road?.url}>
              {road?.title}
            </Link>
          ))}
      </div>
    </div>
  )
}

export default MenuRoad

const styles = {
  container: css`
    position: relative;
    .menu_url {
      position: absolute;
      background: white;
      max-height: calc(100vh - 100px);
      overflow: hidden;
      overflow-y: auto;
      border: 1px solid #f8f8f8;
      box-shadow: 0 10px 30px -8px rgba(0, 0, 0, 0.8);
      padding: 10px 20px;
      border-radius: 8px;
      display: none;
      top: 30px;
      right: -20px;
      z-index: 100;
      &.show {
        display: block;
      }
    }
  `
}
