/* eslint-disable @next/next/no-img-element */
import { useContext, useEffect, useState } from 'react'
import { NextPage } from 'next'
import { css } from '@emotion/react'
import { GlobalContext } from 'context/GlobalContext'
import Places from 'components/maps/Places'
import TollRoadList from 'components/maps/TollRoadList'
import Map from 'components/maps/Map'
import Modal from 'components/global/Modal'
import FormContact from 'components/forms/FormContact'
import { getMapAddress } from 'helpers/filterHelper'
import cookie from 'js-cookie'
import SeoHead from 'components/global/SeoHead'
import ArticleAd from 'components/adsense/ArticleAd'
import { axiosGet } from 'helpers/apiHelpers'
import MenuRoad from 'components/nav/MenuRoad'
import YolItem from 'components/YolItem'
import FooterLegal from 'components/nav/FooterLegal'

type LatLngLiteral = google.maps.LatLngLiteral
type DirectionsResult = google.maps.DirectionsResult

const IndexPage: NextPage = () => {
  const {
    site,
    _local,
    setContext,
    modalData,
    vehicle_class,
    setService,
    fuel,
    service
  } = useContext(GlobalContext)

  const [directions, setDirections] = useState<DirectionsResult>()
  const [startPoint, setStartPoint] = useState<LatLngLiteral>()
  const [endPoint, setEndPoint] = useState<LatLngLiteral>()
  const [selectedRoute, setRoute] = useState(0)
  const [openLegs, setopenLegs] = useState(false)
  const [legs, setLegs] = useState<google.maps.DirectionsStep[]>()
  const [show, setShow] = useState(false)
  const [roads, setRoads] = useState(Object)

  const [about, setAbout] = useState(Object)

  useEffect(() => {
    if (modalData?.name == 'about') {
      axiosGet('/page', {
        lang_relation: '64313fa5872c1'
      }).then((res: any) => {
        if (!res.error) setAbout(res)
      })
    }
  }, [setContext, modalData?.name])

  useEffect(() => {
    axiosGet('pages', {
      select: 'title,short,url,media',
      parent_id: '643679de6200c'
    }).then((res: any) => {
      if (!res.error) setRoads(res)
    })
  }, [])

  const setFuel = () => {
    var fuel_average = document.getElementById(
      'fuel_average'
    ) as HTMLInputElement
    var fuel_price = document.getElementById('fuel_price') as HTMLInputElement

    if (fuel_average?.value && fuel_price?.value) {
      var fuel = {
        fuel_average: parseFloat(fuel_average?.value),
        fuel_price: parseFloat(fuel_price?.value)
      }
      setContext('fuel', fuel)
      localStorage.setItem('fuel', JSON.stringify(fuel))
    } else {
      setContext('fuel', {})
      localStorage.removeItem('fuel')
    }
  }

  useEffect(() => {
    if (
      fuel?.fuel_average &&
      service?.toll_price_total &&
      service?.distance?.value
    ) {
      setFuel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuel?.fuel_average, service?.toll_price_total, service?.distance?.value])

  const fetchDirections = async (type: string, newPoint: LatLngLiteral) => {
    if (type == 'start_point') {
      setStartPoint(newPoint)
    } else if (type == 'end_point') {
      setEndPoint(newPoint)
    }
    setRoute(0)

    const map_api_url = 'https://maps.googleapis.com/maps/api/geocode/json?'

    fetch(
      map_api_url +
        'latlng=' +
        newPoint.lat +
        ',' +
        newPoint.lng +
        '&key=AIzaSyBNJqiSDhErDPY6KF_Snb2rL8PMJTjSkCQ'
    )
      .then((res) => res.json())
      .then((data) => {
        var result = data.results[0]

        const address = result?.address_components
        const { country, city, district } = getMapAddress(address)

        if (type == 'start_point') {
          cookie.set('city', city)
          setService({
            city: city,
            start_point: {
              type: result?.types[0],
              name: result?.name,
              address: result?.formatted_address,
              district: district,
              city: city,
              country: country,
              place_id: result?.place_id
            }
          })
        }
        if (type == 'end_point') {
          setService({
            end_point: {
              type: result?.types[0],
              name: result?.name,
              address: result?.formatted_address,
              district: district,
              city: city,
              country: country,
              place_id: result?.place_id
            }
          })
        }
      })
  }

  useEffect(() => {
    var legs = directions?.routes[selectedRoute].legs[0].steps
    setLegs(legs)
  }, [directions, selectedRoute])

  useEffect(() => {
    /* if (startPoint) fetchDirections('start_point', startPoint)
    if (endPoint) fetchDirections('end_point', endPoint) */

    if (startPoint && endPoint) {
      const service = new google.maps.DirectionsService()
      service.route(
        {
          origin: startPoint,
          destination: endPoint,
          provideRouteAlternatives: true,
          travelMode: google.maps.TravelMode.DRIVING
          /* avoidFerries: Boolean,
          avoidHighways: Boolean,
          avoidTolls: Boolean, */
        },
        (result, status) => {
          if (status === 'OK' && result) {
            setDirections(result)
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPoint, endPoint])

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const current_position = { lat, lng }
          setStartPoint(current_position)
          fetchDirections('start_point', current_position)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* if (!isLoaded) return <div>...</div> */

  return (
    <div css={styles.container}>
      <SeoHead
        title={site?.seo_title}
        description={site?.seo_description}
        hreflangs={[{ lang: 'tr', href: 'https://.yolucretleri.com' }]}
        cononical={'https://yolucretleri.com'}
      />

      <Modal name="about">
        <h2>{about?.title}</h2>
        <div
          className="html"
          dangerouslySetInnerHTML={{ __html: about?.content }}
        ></div>
        <hr />
        <br />
        <h2>{_local('Hata Bildir')}</h2>
        <FormContact />
      </Modal>

      <Modal name="bug">
        <FormContact />
      </Modal>

      <div>
        <div className="about_con">
          <button
            className="btn-s light"
            onClick={() =>
              setContext({
                modalData: { type: 'success', name: 'about' }
              })
            }
          >
            {_local('Hakkında')}
          </button>

          <button
            className="btn-s light"
            onClick={() =>
              setContext({
                modalData: {
                  type: 'alert',
                  name: 'bug',
                  title: 'Hata Bildir'
                }
              })
            }
          >
            {_local('Hata Bildir')}
          </button>
        </div>
        <div className="controls_con">
          <div className="header max flex flex-space-between flex-middle">
            <div className="flex gap-10 flex-middle">
              <img
                className="logo"
                src="/logo.svg"
                width={44}
                height={44}
                alt="logo"
              />
              <b>{_local('Türkiye Yol Ücretleri')}</b>
            </div>
            <div className="flex gap-20">
              <MenuRoad />
              <div className="pointer" onClick={() => setShow(!show)}>
                <img
                  className={!show ? 'show-hide' : ''}
                  src="/media/icon-arrow-down.svg"
                  width={20}
                  height={20}
                  alt="menu"
                />
              </div>
            </div>
          </div>

          {!show && (
            <div className="controls_inner">
              <div className="controls">
                <Places
                  key="start_point"
                  className="start_point"
                  placeholder={_local('baslangic_yeri')}
                  setPoint={async (position) => {
                    setStartPoint(position)
                  }}
                />
                <Places
                  key="end_point"
                  className="end_point"
                  placeholder={_local('varis_yeri')}
                  setPoint={(position) => {
                    setEndPoint(position)
                  }}
                />
              </div>

              {directions && (
                <div>
                  <div className="max flex flex-space-between gap-10 m-bottom-5">
                    <div className="form_group sinif">
                      <label>{_local('Araç Sınıfı')}</label>
                      <select
                        defaultValue={vehicle_class}
                        onChange={(e) =>
                          setContext({
                            vehicle_class: parseInt(e.target.value)
                          })
                        }
                      >
                        <option value={0}>{_local('Otomobil')}</option>
                        <option value={1}>{_local('Hafif Ticari Araç')}</option>
                        <option value={2}>{_local('3. Sınıf Araç')}</option>
                        <option value={3}>{_local('4. Sınıf Araç')}</option>
                        <option value={4}>{_local('5. Sınıf Araç')}</option>
                        <option value={5}>{_local('Motosiklet')}</option>
                      </select>
                    </div>
                    <div className="form_group">
                      <label>{_local('Ortalama Yakıt')}</label>
                      <input
                        id="fuel_average"
                        className="fuel fuel_average"
                        type="number"
                        min={0}
                        name="fuel_average"
                        defaultValue={fuel?.fuel_average}
                        onChange={(y) => setFuel()}
                        placeholder={_local('Litre')}
                      />
                    </div>
                    <div className="form_group">
                      <label>{_local('Yakıt Ücreti')}</label>
                      <input
                        id="fuel_price"
                        className="fuel fuel_price"
                        type="number"
                        name="fuel_price"
                        min={0}
                        defaultValue={fuel?.fuel_price}
                        onChange={() => setFuel()}
                        placeholder={_local('Fiyat ₺')}
                      />
                    </div>
                  </div>
                  <div className="flex flex-space-between m-bottom-5">
                    {directions?.routes?.map((item, index) => (
                      <button
                        className={
                          index == selectedRoute
                            ? 'btn-m primary '
                            : 'btn-m light'
                        }
                        key={index}
                        onClick={() => setRoute(index)}
                      >
                        <span>
                          <b>
                            {_local('rota')} {index + 1}
                            {' - '}
                          </b>
                          {item?.legs[0]?.distance?.text}
                          <br />
                          <small>{item?.legs[0]?.duration?.text}</small>
                        </span>
                      </button>
                    ))}
                  </div>

                  <TollRoadList
                    route={selectedRoute}
                    leg={directions?.routes[selectedRoute]?.legs[0]}
                  />

                  <ArticleAd />

                  <button
                    className="max clearfix btn-s bordered secondary m-0 f-center"
                    onClick={() => setopenLegs(!openLegs)}
                  >
                    {openLegs
                      ? _local('yol_tarifi_gizle')
                      : _local('yol_tarifi_goster')}
                  </button>

                  {legs && openLegs && (
                    <div className="legs_con m-top-20">
                      <b>{_local('yol_tarifi')}</b>
                      <hr />
                      {legs?.map((leg, index) => (
                        <div key={index} className="legs">
                          <div className="icon">
                            {leg?.maneuver && (
                              <img
                                width={18}
                                height={18}
                                src={'map-icon/' + leg?.maneuver + '.png'}
                                alt={leg?.maneuver}
                              />
                            )}
                          </div>
                          <div>{index + 1}</div>
                          <div
                            className="html"
                            dangerouslySetInnerHTML={{
                              __html: leg?.instructions
                            }}
                          ></div>
                          <div>{leg?.distance?.text}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="map">
        <Map
          directions={directions}
          startPoint={startPoint}
          endPoint={endPoint}
          selectedRoute={selectedRoute}
          setRoute={setRoute}
          fetchDirections={fetchDirections}
        />
      </div>

      {roads.length > 0 && (
        <div className="max w1200 m-top-30">
          <h1 className="t-center">{_local('Türkiye Yol Listesi')}</h1>
          <div className="max flex gap-20 flex-wrap flex-center">
            {roads?.map((road: any, index: number) => (
              <YolItem key={index} road={road} />
            ))}
          </div>
        </div>
      )}
      <FooterLegal />
    </div>
  )
}

export default IndexPage

const styles = {
  container: css`
    position: relative;
    width: 100%;
    height: 100vh;
    .about_con {
      position: absolute;
      left: 8px;
      bottom: 25px;
      z-index: 99;
      text-align: center;
      transition: 200ms;
      button {
        margin: 5px 0;
      }
    }
    .controls_con {
      position: absolute;
      display: block;
      top: 20px;
      left: 20px;
      width: 100%;
      max-width: 500px;
      max-height: calc(100vh - 40px);
      text-align: left;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
      border-bottom: 1px solid var(--borderColor);
      border-radius: 12px;
      z-index: 100;

      @media only screen and (max-width: 480px) {
        top: 0;
        left: 0;
        border-radius: 0 0 8px 8px;
        max-height: 100vh;
      }
      .header {
        padding: 18px;
      }
      .fuel {
        min-width: 90px;
        padding-left: 32px;
        padding-right: 3px;
        background: white url(media/icon-avarage.svg) left 5px center no-repeat;
        &.fuel_price {
          background: white url(media/icon-fuel.svg) left 5px center no-repeat;
        }
      }

      .show-hide {
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in-out;
      }
      .controls_inner {
        position: relative;
        width: 100%;
        padding: 0 18px 15px;
        max-width: 600px;
        max-height: calc(100vh - 140px);
        overflow: hidden;
        overflow-y: auto;
        .btn-m {
          width: 100%;
          float: left;
          height: auto;
          padding: 5px 5px;
          margin: 0 0 10px 10px;
          font-size: 15px;
          &:first-of-type {
            margin-left: 0;
          }
          small {
            display: block;
            font-size: 12px;
            line-height: 0.9;
            margin-top: 3px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 12px;
            small {
              font-size: 11px;
            }
          }
        }
        .legs_con {
          .legs {
            width: 100%;
            display: grid;
            grid-template-columns: 20px 20px auto 60px;
            gap: 5px;
            font-size: smaller;
            text-align: left;
            padding: 5px 0;
            border-bottom: 1px solid var(--borderColor);
          }
        }
        .controls {
          width: 100%;
          transition: 300ms;
          margin: 0 auto;
          .search-location-input {
            position: relative;
            width: 100%;
            .btn_delete {
              position: absolute;
              display: none;
              width: 20px;
              height: 20px;
              bottom: 17px;
              right: 5px;
              background: #11111146 url(media/icon-close-w.svg) center center
                no-repeat;
              background-size: 60% 60%;
              border-radius: 50%;
              z-index: 1;
              &:hover {
                display: block;
              }
            }
            .start_point {
              margin-top: 0;
              font-family: var(--secondaryFont);
              font-size: 15px;
              padding-left: 32px;
              background: white url(media/input-start.svg) left 5px center
                no-repeat;
              &:hover {
                + .btn_delete {
                  display: block;
                }
              }
            }
            .end_point {
              font-family: var(--secondaryFont);
              font-size: 15px;
              padding-left: 32px;
              margin-bottom: 5px;
              background: white url(media/input-end.svg) left 5px center
                no-repeat;
              &:hover {
                + .btn_delete {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .map {
      width: 100%;
      height: 100%;
      border-left: none;
      overflow: hidden;
      transition: 300ms;
      .info_con {
        height: 100%;
        display: flex;
        align-items: left;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        padding-left: 60px;
        text-align: left;
        p {
          color: #fff;
          font-size: 36px;
          line-height: 1.1;
          font-weight: 700;
        }
      }
      .map-container {
        width: 100%;
        height: 100%;
      }
    }
  `
}
